export default {
    DISPLAY_KEYS: [
        {
            field: 'name',
            key: 'USERNAME',
            type: 'text'
        },
        {
            field: 'id',
            key: 'ACCOUNT_ID',
            type: 'text'
        },
        {
            field: 'cerebro_id',
            key: 'CEREBRO_ID',
            type: 'text'
        },
        {
            field: 'region',
            key: 'REGION',
            type: 'text'
        },
        {
            field: 'last_login',
            key: 'LAST_LOGIN',
            type: 'date'
        },
        {
            field: 'trophy',
            key: 'ARENA',
            type: 'arena'
        },
        {
            field: 'is_premium',
            key: 'PREMIUM',
            type: 'boolean'
        },
        {
            field: 'units',
            key: 'CLAIMED_UNITS',
            type: 'claimed'
        },
        {
            field: 'heroes',
            key: 'CLAIMED_HEROES',
            type: 'claimed'
        },
        {
            field: 'tracker',
            key: 'PUBLISHER_NAME',
            type: 'publisher_name'
        },
        {
            field: 'tracker',
            key: 'CAMPAIGN_NAME',
            type: 'campaign_name'
        },
        {
            field: 'tracker',
            key: 'SUB_AD_GROUP',
            type: 'sub_ad_group'
        },
        {
            field: 'tracker',
            key: 'CREATIVE',
            type: 'creative'
        }
    ],
    FULL_SEVEN_DAY_REWARDS : [
        {
            claimed: false,
            name : 'day 1 rewards',
            reward_id: "1"
        },
        {
            claimed: false,
            name : 'day 2 rewards',
            reward_id: "2"
        },
        {
            claimed: false,
            name : 'day 3 rewards',
            reward_id: "3"
        },
        {
            claimed: false,
            name : 'day 4 rewards',
            reward_id: "4"
        },
        {
            claimed: false,
            name : 'day 5 rewards',
            reward_id: "5"
        },
        {
            claimed: false,
            name : 'day 6 rewards',
            reward_id: "6"
        },
        {
            claimed: false,
            name : 'day 7 rewards',
            reward_id: "7"
        },
    ],
    CHEST_STATES: [
        {
            value: -1,
            name: "No Chest"
        },
        {
            value: 0,
            name: "Ready to Start"
        },
        {
            value: 1,
            name: "Started"
        }
    ]
}