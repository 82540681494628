<template>
    <div class="container">
        <div class="cell">
            <p>{{ props.index+1 }}</p>
        </div>
        <div class="cell">
            <p>{{ getPurchaseType() }}</p>
        </div>
        <div class="cell">
            <p>{{ getAdditionalInfo() }}</p>
        </div>    
        <div class="cell">
            <p>{{ getDate() }}</p>
        </div> 
        <div class="cell">
            <p>{{ getTransactionId() }}</p>
        </div> 
        <div class="cell">
            <Button :loading="isLoading" type="info" :text="detailData ? 'Close' : 'Get Receipt' " :disabled="!props.purchase.receipt_id" :onClick="() => getReceipt()" />
        </div> 
    </div>
    <div class="jsondata" v-if="detailData">
        <json-viewer sort boxed copyable :value="detailData" />
    </div>
</template>

<script>

import moment from "moment"
import Button from '../../../components/common/Button.vue'
import { ref } from '@vue/reactivity'
import receiptManager from '../../../services/receipt'
import 'vue-json-viewer/style.css'

export default {
    name: "Purchase",
    components: {
        Button
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        purchase: {
            type: Object,
            required: true
        }
    },
    setup (props) {

        const isLoading = ref(false)

        const detailData = ref()

        const getPurchaseType = () => {
            switch (props.purchase.type) {
                case 0:
                    return "Gem Pack"
                case 1:
                    return "Season Pass"
                case 2: 
                    return "Promotion"
                case 3: 
                    return "Arena Pack"
                default:
                    return "UNDEFINED TYPE"
            }
        }

        const getAdditionalInfo = () => {
            switch (props.purchase.type) {
                case 0:
                    return props.purchase.amount
                case 1:
                    return ""
                case 2: 
                    return props.purchase.promotion_id
                case 3: 
                    return props.purchase.arena
                default:
                    return ""
            }
        }

        const getDate = () => {
            return moment(props.purchase.date).format('DD/MM/YYYY, h:mm a');
        }

        const getTransactionId = () => {
            return props.purchase.transaction_id || "NOT_AVAILABLE"
        } 

        const getReceipt = async () => {
            if (detailData.value) {
                detailData.value = undefined
                return
            }

            isLoading.value = true
            receiptManager.getReceipt(props.purchase.receipt_id).then(result => {
                detailData.value = result
            }).finally(() => {
                isLoading.value = false
            })
        }

        return {
            getAdditionalInfo,
            getPurchaseType,
            getDate,
            getTransactionId,
            props,
            isLoading,
            getReceipt,
            detailData
        }
    }
}


</script>

<style scoped>

.container{
    border-bottom: 1px solid grey;
    min-height: 50px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1fr;
    padding: 5px 20px;
}

.cell{
    display: flex;
    justify-content: left;
    align-items: center;
}

.jsondata{
    text-align: left;
}

</style>