

const manipulateUserCreationTime = (userData, creationDate) => {
    if (creationDate > Date.now())
        return;
    
    userData.create_date = creationDate;
}

const getUserSegment = (moneySpent) => {
    if (moneySpent < 5)
        return "Minnows"
    if (moneySpent < 100)
        return "Dolphins"
    return "Whales"
}

const maxChar = (userData) => {
    userData.gem = 9999999
    userData.gold = 9999999
    userData.is_trophy_manipulated = true;
    Object.keys(userData.units).forEach(unitId => {
        userData.units[unitId].count = 10000
    })
    Object.keys(userData.heroes).forEach(heroId => {
        userData.heroes[heroId].count = 10000
    })

    return userData
}

module.exports = {
    manipulateUserCreationTime,
    getUserSegment,
    maxChar
}