import getAxios from './axios'

export default {
    getReceipt: (receiptId) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/purchases/get/receipt?receipt_id=' + receiptId).then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
}