<template>
    <div>
        <div class="container">
            <div class="cell">Index</div>
            <div class="cell">Product Type</div>
            <div class="cell">Specific Value</div>
            <div class="cell">Date</div>
            <div class="cell">Transaction ID</div>
            <div class="cell">Action</div>
        </div>
        <div v-for="(element, index) in userObject.purchase_history" v-bind:key="index">
            <PurchaseElement :index="index" :purchase="element" />
        </div>
    </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import { ref } from '@vue/reactivity'
import PurchaseElement from './purcahse.vue'

export default {
    name: "UserPurchases",
    components: {
        PurchaseElement
    },
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
    setup (props) {
        const userObject = ref({ purchase_history: [] })

        onMounted(() => {
            userObject.value = JSON.parse(JSON.stringify(props.userData))
        })

        return {
            userObject
        }
    }
}

</script>

<style scoped>

.container{
    border-bottom: 1px solid grey;
    min-height: 50px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1fr;
    padding: 5px 20px;
}

.cell{
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
}

</style>