<template>
  <div class="container">
    <div class="search-bar">
      <div class="form-container">
        <input type="radio" id="one" value="name" v-model="pickedKey" />
        <label for="one">Username</label>
        <input type="radio" id="two" value="id" v-model="pickedKey" />
        <label for="two">User Id</label>
        <input type="radio" id="three" value="cerebro_id" v-model="pickedKey" />
        <label for="three">Cerebro Id</label>
      </div>
      <input v-on:keyup.enter="searchUser" v-model="pickedValue" type="text" />
      <Button
        text="Search"
        :loading="isSearching"
        type="info"
        :onClick="searchUser"
      />
    </div>
    <div v-if="user">
      <div class="user-editable-content">
        <div class="form-container">
          <span>{{ $t("TROPHY") }}</span>
          <input v-model.number="editableTrophy" type="number" />
        </div>
        <div class="form-container">
          <span>{{ "Gold" }}</span>
          <input v-model.number="editableGold" type="number" />
        </div>
        <div class="form-container">
          <span>{{ "Gem" }}</span>
          <input v-model.number="editableGem" type="number" />
        </div>
        <div class="form-container">
          <span>{{ $t("SEASON_PASS_TOKEN") }}</span>
          <input v-model.number="editableSPToken" type="number" />
        </div>
        <div class="form-container">
          <span>{{ $t("Coop Key") }}</span>
          <input v-model.number="editableCoopKey" type="number" />
        </div>
        <div class="form-container">
          <span>{{ $t("BAN") }}</span>
          <input type="checkbox" v-model="editableBan" />
        </div>
        <div class="form-container">
          <span>{{ $t("USER_VALUES.IS_PREMIUM") }}</span>
          <input type="checkbox" v-model="isPremiumUser" />
        </div>
        <div class="form-container">
          <span>{{ $t("Excluded From Leaderboard") }}</span>
          <input type="checkbox" v-model="isLeaderboard" />
        </div>
        <Button
          text="User Logs"
          :loading="false"
          type="info"
          :onClick="
            () => {
              $router.push({ name: 'GameLogs', query: { user_id: user.id } });
            }
          "
        />
        <Button
          text="Update User"
          :loading="isUpdating"
          type="success"
          :onClick="editUserFields"
        />
        <Button
          text="Delete User"
          :loading="isDeleting"
          type="error"
          :onClick="showPopup"
        />
      </div>
    </div>
    <div class="menu" v-if="user">
      <div
        :class="`menu-item ${
          selectedTabIndex === index ? 'menu-item-selected' : ''
        } ${index === 0 ? 'menu-item-left' : ''} ${
          index === tabOptions.length - 1 ? 'menu-item-right' : ''
        }`"
        v-for="(element, index) in tabOptions"
        v-bind:key="index"
        @click="() => switchTab(index)"
      >
        <p>{{ element }}</p>
      </div>
    </div>
    <div v-if="selectedTabIndex === 0 && user">
      <div class="user-editable-content" v-if="user">
        <button
          v-if="['staging', 'dev'].includes(env)"
          class="info"
          @click="maxCharApply"
        >
          {{ "Max Char" }}
        </button>
        <button class="info" @click="resetUserTrophyRoad">
          {{ $t("USER_VALUES.RESET_TROPHY_ROAD") }}
        </button>
        <button class="info" @click="claimTrophyRoad">
          {{ "Claim Trophy Road Rewards" }}
        </button>
        <button class="info" @click="completeZeroToHeroQuests">
          {{ "Complete ZeroToHero Quests" }}
        </button>
        <button class="info" @click="resetUserSeasonPass">
          {{ $t("USER_VALUES.RESET_SEASON_PASS") }}
        </button>
        <button class="info" @click="completeTutorial">
          {{ "Complete Tutorial" }}
        </button>
        <button class="info" @click="resetTutorialProgress">
          {{ $t("USER_VALUES.RESET_TUTORIAL_PROGRESS") }}
        </button>
        <button class="info" @click="deleteFromLeaderboard">
          {{ "Delete From Leaderboard" }}
        </button>
      </div>
      <div class="user-editable-content" v-if="user">
        <button class="info" @click="enableAllSevenDayRewards">
          {{ "Enable All Seven Day Login Rewards" }}
        </button>
        <button class="info" @click="enableAllDailyRewards">
          {{ "Enable All Daily Login Rewards" }}
        </button>
        <button class="info" @click="refreshDailyShop">
          {{ "Reset Daily Shop" }}
        </button>
        <button class="info" @click="enableDailyDealsRefresh">
          {{ "Enable Refresh Daily Deals" }}
        </button>
        <button class="info" @click="obtainAllEmotes">
          {{ "Give All Emotes" }}
        </button>
        <button class="info" @click="refreshEventRoadRewards">
          Refresh Event Road Rewards
        </button>
        <button class="info" @click="refreshEventTreasureStates">
          Refresh Event Treasure States
        </button>
        <button class="info" @click="changeEventPremiumStatus">
          Change Event Premium Status
        </button>
        <button class="info" @click="resetEventTutorial">
          Reset Event Tutorial
        </button>
      </div>
      <div class="user-editable-content" v-if="user">
        <button class="info" @click="restartSeasonWithDifferentQuests">
          {{ $t("USER_VALUES.RESTART_SEASON_WITH_DIFFERENT_QUESTS") }}
        </button>
        <button class="info" @click="refreshDailyQuests">
          {{ "Refresh Daily Quests" }}
        </button>
        <button class="info" @click="setAllDailyQuestsCompleted">
          {{ "Complete Daily Quests" }}
        </button>
        <button class="info" @click="completeCareerQuest">
          {{ "Complete Career Quest" }}
        </button>
        <button class="info" @click="setAllBeginnerQuestsCompleted">
          {{ "Complete Beginner Quests" }}
        </button>
        <button class="info" @click="removeAllBeginnerQuests">
          {{ "Remove Beginner Quests" }}
        </button>
        <button class="info" @click="setAllSeasonalQuestsCompleted(false)">
          {{ "Complete Seasonal Free Quests" }}
        </button>
        <button class="info" @click="setAllSeasonalQuestsCompleted(true)">
          {{ "Complete Seasonal Premium Quests" }}
        </button>
      </div>
      <div class="user-content" v-if="user">
        <div class="row">
          <div style="text-align: start">
            {{ $t("USER_VALUES.DETAILED_MATCH_RESULTS") }}
          </div>
          <div style="text-align: start">
            <a
              target="_blank"
              :href="
                'https://codeway.cloud.looker.com/explore/codeway/randombrawl_logs?fields=randombrawl_logs._event_time,randombrawl_logs.match_id,randombrawl_logs.match_result,randombrawl_logs.match_length,randombrawl_logs.bot_game,randombrawl_logs.is_coop&f[randombrawl_logs.app]=events.randombrawl&f[randombrawl_logs.event_name]=match_result&f[randombrawl_account_facts.account_id]=' +
                user.id +
                '&sorts=randombrawl_logs._event_time+desc&limit=500'
              "
              >Looker Link</a
            >
          </div>
        </div>
        <div
          class="row"
          v-for="(property, index) in userConstants.DISPLAY_KEYS"
          v-bind:key="index"
        >
          <div style="text-align: start">
            {{ $t("USER_VALUES." + property.key) }}
          </div>
          <div style="text-align: start">
            {{ prepareField(property.type, user[property.field]) }}
          </div>
        </div>
        <div class="row">
          <div style="text-align: start">{{ "Purchase Count" }}</div>
          <div style="text-align: start">{{ totalPurchaseCount }}</div>
        </div>
        <div class="row">
          <div style="text-align: start">{{ "Purchase Amount ($)" }}</div>
          <div style="text-align: start">{{ totalPurchaseAmount }}</div>
        </div>
        <div class="row">
          <div style="text-align: start">{{ "Purchase Segment" }}</div>
          <div style="text-align: start">{{ userSegment }}</div>
        </div>
        <div class="row">
          <div style="text-align: start">{{ "Win Count" }}</div>
          <input v-model.number="editableWin" type="number" min="0" />
        </div>
        <div class="row">
          <div style="text-align: start">{{ "Lose Count" }}</div>
          <input v-model.number="editableLose" type="number" min="0" />
        </div>
        <div class="row">
          <div style="text-align: start">{{ "Creation Date" }}</div>
          <DatePicker
            v-model="editableCreationDate"
            mode="dateTime"
            :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                id="creation_date"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="row">
          <div style="text-align: start">
            {{ "Next 7 day login reward date" }}
          </div>
          <DatePicker
            v-model="nextSevenDayRewardDate"
            mode="dateTime"
            :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                id="creation_date"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="row">
          <div style="text-align: start">
            {{ "Next daily login reward date" }}
          </div>
          <DatePicker
            v-model="nextDailyRewardDate"
            mode="dateTime"
            :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                id="creation_date"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div v-if="user.match_data" class="row">
          <div style="text-align: start">{{ "Matchmaking Tier" }}</div>
          <input
            v-model.number="user.match_data.matchmaking_tier"
            type="number"
            min="1"
          />
        </div>
        <div class="row">
          <div style="text-align: start">{{ "Matchmaking Test Category" }}</div>
          <select v-model="user.testuser_matchmaking_type">
            <option
              v-for="(option, index) in [
                { text: 'Real Match Group', value: 0 },
                { text: 'Normal Group', value: 1 },
              ]"
              :value="option.value"
              v-bind:key="index"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="row">
          <div style="text-align: start">{{ "Is Matchmaking Test User" }}</div>
          <input v-model="isMatchmakingTestUser" type="checkbox" />
        </div>
      </div>
    </div>
    <div v-if="selectedTabIndex === 1 && user">
      <div v-if="!editableThematicEvent && !editableTreasureHuntEvent">
        <h4>No Event For User</h4>
      </div>
      <div v-if="editableTreasureHuntEvent">
        <h4>Treasure Hunt Event</h4>
        <div class="event-container">
          <div class="form-container-event">
            <span>{{ "Soft Currency" }}</span>
            <input
              v-model.number="editableTreasureHuntEvent.soft_currency"
              type="number"
            />
          </div>
          <div class="form-container-event">
            <span>{{ "Hard Currency" }}</span>
            <input
              v-model.number="editableTreasureHuntEvent.hard_currency"
              type="number"
            />
          </div>
          <div class="form-container-event">
            <span>{{ "Is Premium" }}</span>
            <input
              v-model.number="editableTreasureHuntEvent.is_premium"
              type="checkbox"
            />
          </div>
        </div>
      </div>
      <div v-if="editableThematicEvent">
        <h4>Thematic Live Ops Event</h4>
        <div class="event-container">
          <div class="form-container-event">
            <span>{{ "Soft Currency" }}</span>
            <input
              v-model.number="editableThematicEvent.soft_currency"
              type="number"
            />
          </div>
          <div class="form-container-event">
            <span>{{ "Hard Currency" }}</span>
            <input
              v-model.number="editableThematicEvent.hard_currency"
              type="number"
            />
          </div>
          <div class="form-container-event">
            <span>{{ "Is Premium" }}</span>
            <input
              v-model.number="editableThematicEvent.is_premium"
              type="checkbox"
            />
          </div>
          <div class="form-container-event" v-if="editableThematicEvent.quests">
            <span>{{ "Silver Collection" }}</span>
            <input
              v-model.number="editableThematicEvent.collection['0'].count"
              type="number"
            />
          </div>
          <div class="form-container-event" v-if="editableThematicEvent.quests">
            <span>{{ "Gold Collection" }}</span>
            <input
              v-model.number="editableThematicEvent.collection['1'].count"
              type="number"
            />
          </div>
          <div class="form-container-event" v-if="editableThematicEvent.quests">
            <span>{{ "Diamond Collection" }}</span>
            <input
              v-model.number="editableThematicEvent.collection['2'].count"
              type="number"
            />
          </div>
          <div class="form-container-event" v-if="editableThematicEvent.quests">
            <span>{{ "Event Pass Token" }}</span>
            <input
              v-model.number="editableThematicEvent.event_pass.token"
              type="number"
            />
          </div>
        </div>
      </div>
      <div v-if="editableCoop">
        <h4>Coop</h4>
        <div class="event-container">
          <div class="form-container-event">
            <span>{{ "Match Refresh Time" }}</span>
            <DatePicker
              v-model="editableCoop.refresh_time"
              mode="dateTime"
              :model-config="{
                type: 'number',
                mask: 'DD/MM/YYYY HH:mm',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  id="refresh_time"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div class="form-container-event">
            <span>{{ "Max Matches" }}</span>
            <input v-model.number="editableCoop.max_matches" type="number" />
          </div>
          <div class="form-container-event">
            <span>{{ "Remaining Matches" }}</span>
            <input
              v-model.number="editableCoop.remaining_matches"
              type="number"
            />
          </div>
          <div class="form-container-event">
            <span>{{ "Remaining Ads" }}</span>
            <input v-model.number="editableCoop.remaining_ads" type="number" />
          </div>
          <div class="form-container-event">
            <span>{{ "Curren Ban Tier" }}</span>
            <input
              v-model.number="editableCoop.current_penalty_tier"
              type="number"
            />
          </div>
          <div class="form-container-event">
            <span>{{ "Banned Until" }}</span>
            <DatePicker
              v-model="editableCoop.banned_until"
              mode="dateTime"
              :model-config="{
                type: 'number',
                mask: 'DD/MM/YYYY HH:mm',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  id="banned_until"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-container" v-if="selectedTabIndex === 2 && user">
      <Purchases :userData="user" />
    </div>
    <div v-if="selectedTabIndex === 3 && user">
      <div class="user-units-table" v-if="user">
        <div class="table-header" @click="toggleUnitPanel()">
          <h3>Units</h3>
        </div>
        <div v-if="unitPanelOpen">
          <div
            class="user-units-row"
            v-for="unit in data.units"
            v-bind:key="unit.id"
          >
            <p>{{ unitsArr.find((x) => x.id === unit.id)?.name }}</p>
            <input v-model.number="unit.amount" type="number" min="0" />
          </div>
        </div>
      </div>
      <div class="user-units-table" v-if="user">
        <div class="table-header" @click="toggleHeroPanel()">
          <h3>Heroes</h3>
        </div>
        <div v-if="heroPanelOpen">
          <div
            class="user-units-row"
            v-for="hero in data.heroes"
            v-bind:key="hero.id"
          >
            <p>{{ heroesArr.find((x) => x.id === hero.id)?.name }}</p>
            <input v-model.number="hero.amount" type="number" min="0" />
          </div>
        </div>
      </div>
      <div class="user-units-table" v-if="user">
        <div class="table-header" @click="toggleChestPanel()">
          <h3>Chests</h3>
        </div>
        <div v-if="chestsPanelOpen" class="user-chest-container">
          <div
            class="user-chest"
            v-for="(chest, index) in user.chests"
            v-bind:key="chest.id"
          >
            <select v-model="chest.id">
              <option
                v-for="option in chests"
                :value="option.id"
                v-bind:key="option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <div class="row-noborder">
              <div style="text-align: start">{{ "Arena" }}</div>
              <input v-model.number="chest.arena" type="number" min="0" />
            </div>
            <div class="row-noborder">
              <div style="text-align: start">
                {{ "Time Required (In Hours)" }}
              </div>
              <input
                v-model.number="chest.time_required"
                type="number"
                min="0"
              />
            </div>
            <div class="form-container">
              <span>{{ "Chest State" }}</span>
              <select v-model="chestStates[index]">
                <option
                  v-for="state in userConstants.CHEST_STATES"
                  :value="state.value"
                  v-bind:key="state.value"
                >
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="row-noborder">
              <div style="text-align: start">{{ "Opening Time" }}</div>
              <DatePicker
                v-model="chest.opening_time"
                mode="dateTime"
                :model-config="{
                  type: 'number',
                  mask: 'DD/MM/YYYY HH:mm',
                }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    id="creation_date"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </DatePicker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmPopup
      :popup-open="popupOpen"
      :text="popupText"
      :type="popupType"
      @popup-close="popupOpen = false"
      @confirm="deleteUser"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  reactive,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
} from "vue";
import moment from "moment";
import "v-calendar/dist/style.css";
import store from "@/store";
import userConstants from "@/constants/userConstants";
import unitConstants from "@/constants/unitConstants";
import { getUserSegment, maxChar } from "../../util/user";
import axios from "axios";
import dispatchMap from "@/constants/dispatchMap";
import Button from "../../components/common/Button.vue";
import ConfirmPopup from "../../components/common/ConfirmPopup.vue";
import Purchases from "./components/purchases.vue";

export default {
  name: "index",
  components: {
    Button,
    ConfirmPopup,
    Purchases,
  },
  setup() {
    const usedPages = ["users"];
    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });
    });
    const pickedKey = ref("name");

    const tabOptions = [
      "Main",
      "Events & Coop",
      "Purchases",
      "Game Elements",
      "Account Transfer",
    ];
    const selectedTabIndex = ref(0);

    const pickedValue = ref("");
    const user = computed(() => store.getters["user/getUser"]);
    const chests = computed(() => store.getters["chests/getChests"]);
    const unitsArr = ref([]);
    watch(
      () => store.getters["units/getUnits"],
      (value) => {
        unitsArr.value = [...value].sort((a, b) => a.index - b.index);
      },
      { immediate: true }
    );
    const heroesArr = ref([]);
    watch(
      () => store.getters["heroes/getHeroes"],
      (value) => {
        heroesArr.value = [...value].sort((a, b) => a.index - b.index);
      },
      { immediate: true }
    );

    onMounted(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const chestStates = ref([]);

    const totalPurchaseAmount = ref(0);
    const totalPurchaseCount = ref(0);
    const userSegment = ref("");
    const popupOpen = ref(false);
    const nextDailyRewardDate = ref(0);
    const nextSevenDayRewardDate = ref(0);
    const editableCreationDate = ref(0);
    const editableWin = ref(0);
    const editableLose = ref(0);
    const editableGold = ref(0);
    const editableGem = ref(0);
    const editableTrophy = ref(0);
    const editableSPToken = ref(0);

    const editableCoop = ref(null);
    const editableTreasureHuntEvent = ref(null);
    const editableThematicEvent = ref(null);
    const editableCoopKey = ref(0);
    let userEventData = {};
    const editableBan = ref(undefined);
    const popupText = ref(
      "Are you sure? Change you want to make cannot be reverted."
    );
    const popupType = ref("confirm");
    const unitPanelOpen = ref(false);
    const heroPanelOpen = ref(false);
    const chestsPanelOpen = ref(false);
    const isPremiumUser = ref(undefined);
    const isLeaderboard = ref(undefined);
    const isMatchmakingTestUser = ref(false);
    const data = reactive({
      units: [],
      heroes: [],
    });

    watch(
      () => store.getters["user/getUser"],
      (val) => {
        nextDailyRewardDate.value = val.next_daily_login_reward_time;
        nextSevenDayRewardDate.value = val.next_login_reward_time;
        editableCreationDate.value = val.create_date;
        editableWin.value = val.profile.victories;
        editableLose.value = val.profile.defeats;
        editableGold.value = val.gold;
        editableGem.value = val.gem;
        editableTrophy.value = val.trophy;
        editableCoopKey.value = val.coop_key;
        editableSPToken.value = val.season_pass_token;
        editableBan.value = val.banned;
        totalPurchaseAmount.value = val.total_purchase ?? 0;
        userSegment.value = getUserSegment(totalPurchaseAmount.value);
        isPremiumUser.value = val.is_premium;
        editableCoop.value = val.coop;
        isLeaderboard.value = val.exclude_leaderboard;

        if (val.event_data["treasure-hunt"])
          editableTreasureHuntEvent.value = JSON.parse(
            JSON.stringify(val.event_data["treasure-hunt"])
          );
        if (val.event_data["thematic_liveops"])
          editableThematicEvent.value = JSON.parse(
            JSON.stringify(val.event_data["thematic_liveops"])
          );

        isMatchmakingTestUser.value = val.is_matchmaking_test_user || false;
        data.units = Object.keys(val.units).map((id) => {
          return {
            id: id,
            amount: val.units[id].count,
          };
        });
        data.heroes = Object.keys(val.heroes).map((id) => {
          return {
            id: id,
            amount: val.heroes[id].count,
          };
        });
        getPurchaseData();
      }
    );

    const isSearching = ref(false);
    const searchUser = () => {
      isSearching.value = true;
      if (pickedKey.value === "name")
        pickedValue.value = pickedValue.value.toUpperCase();
      store
        .dispatch("user/getUser", {
          key: pickedKey.value,
          value: pickedValue.value,
        })
        .finally(() => {
          isSearching.value = false;
          popupOpen.value = false;
          chestStates.value = user.value.chests.map((userChest) => {
            if (userChest.opening_time > 0) return 1;
            else return userChest.opening_time;
          });
        });
    };

    const switchTab = (index) => {
      selectedTabIndex.value = index;
    };

    const prepareField = (type, value) => {
      if (!value) {
        return "None";
      }

      if (type === "text") {
        return value;
      }
      if (type === "date") {
        return moment(value).format("MMMM Do YYYY, h:mm a");
      }
      if (type === "season") {
        return Math.floor(value / 100);
      }
      if (type === "claimed") {
        const unlockedCount = Object.keys(value).reduce((acc, val) => {
          return acc + isClaimed(value[val]);
        }, 0);

        const totalCount = Object.keys(value).length;
        return unlockedCount + "/" + totalCount;
      }
      if (type === "boolean") {
        return value ? "True" : "False";
      }
      if (type === "publisher_name") {
        return value.split("::")[0];
      }
      if (type === "campaign_name") {
        return value.split("::")[1] || "None";
      }
      if (type === "sub_ad_group") {
        return value.split("::")[2] || "None";
      }
      if (type === "creative") {
        return value.split("::")[3] || "None";
      }

      return value;
    };

    const isClaimed = (unit) => {
      return (
        unit.count > 0 ||
        unit.level > unitConstants.BASE_LEVELS_BY_RARITY[unit.rarity]
      );
    };
    const toggleUnitPanel = () => {
      unitPanelOpen.value = !unitPanelOpen.value;
    };

    const toggleHeroPanel = () => {
      heroPanelOpen.value = !heroPanelOpen.value;
    };

    const toggleChestPanel = () => {
      chestsPanelOpen.value = !chestsPanelOpen.value;
    };

    const completeTutorial = () => {
      const updateBody = {
        tutorial_progress: JSON.parse(
          JSON.stringify(user.value.tutorial_progress)
        ),
      };
      updateBody.tutorial_progress = 600;

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const resetTutorialProgress = () => {
      const updateBody = {
        tutorial_progress: {},
        gold: 0,
        units: {
          ...user.value.units,
          "9AuYNvoAxqsJqZ0Uhope": {
            rarity: 0,
            count: 1,
            level: 1,
          },
        },
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const deleteFromLeaderboard = () => {
      store.dispatch("leaderboard/deleteMockUser", { id: user.value.id });
    };

    const resetUserTrophyRoad = () => {
      const updateBody = {};
      const trophyLength = user.value.trophy_road.length;

      updateBody.trophy_road = new Array(trophyLength).fill(false);

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const claimTrophyRoad = () => {
      const updateBody = {
        trophy_road: JSON.parse(JSON.stringify(user.value.trophy_road)),
      };

      Object.keys(updateBody.trophy_road).forEach((reward) => {
        if (reward <= user.value.highest_trophy) {
          updateBody.trophy_road[reward] = true;
        } else {
          return;
        }
      });

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const completeCareerQuest = () => {
      const updateBody = {
        career: JSON.parse(JSON.stringify(user.value.career)),
      };
      updateBody.career.current_quest_progress =
        updateBody.career.current_quest.amount;

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const completeZeroToHeroQuests = () => {
      const updateBody = {
        heroes: JSON.parse(JSON.stringify(user.value.heroes)),
        units: JSON.parse(JSON.stringify(user.value.units)),
        gold_spent: 40000,
        highest_trophy:
          user.value.highest_trophy >= 1700 ? user.value.highest_trophy : 1700,
        results: [...Array(70).fill(1)],
        coop_results: [...Array(51).keys()],
        profile: JSON.parse(JSON.stringify(user.value.profile)),
        mission_data: JSON.parse(JSON.stringify(user.value.mission_data)),
        zero_to_hero_mission_data: JSON.parse(
          JSON.stringify(user.value.zero_to_hero_mission_data)
        ),
        zero_to_hero_begin_date: Date.now() - 1000 * 60 * 60 * 24 * 7,
      };
      for (
        let i = 0;
        i < updateBody.mission_data.claimable_login_missions.length;
        i++
      ) {
        updateBody.mission_data.claimable_login_missions[i] = true;
      }
      updateBody.mission_data.pvp_bosses_killed = 70;
      updateBody.mission_data.pvp_fast_monster_killed = 600;
      updateBody.mission_data.pvp_mini_bosses_killed = 500;
      updateBody.mission_data.pvp_mobs_killed = 12000;
      updateBody.mission_data.pvp_hero_spell_used = 120;
      updateBody.profile.victories = 70;
      updateBody.profile.best_wave = 51;

      updateBody.units["iTPb3ST5RMasxiXbiUHF"].level = 13;
      updateBody.units["9AuYNvoAxqsJqZ0Uhope"].level = 13;
      updateBody.units["Jc9Kugc2HGYL1lF5RRmZ"].level = 13;
      updateBody.units["0dOJkz7p6gxtSpXombTK"].level = 13;
      updateBody.units["UL9zqW9rTcc3e3QOL4Kr"].level = 13;
      updateBody.units["Lhcod04fBD2ZuvEMVD8h"].level = 13;
      updateBody.units["81veRVeT2vXGLdliGaje"].level = 13;

      Object.keys(updateBody.units).forEach((unit) => {
        updateBody.units[unit].count = 1;
      });

      Object.keys(updateBody.heroes).forEach((hero) => {
        updateBody.heroes[hero].summoned = true;
        updateBody.heroes[hero].count = 1;
      });

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const resetUserSeasonPass = () => {
      const updateBody = {};

      const spFreeRewardsLength = user.value.season_pass_road.sp_rewards.length;
      const spPremiumRewardsLength =
        user.value.season_pass_road.spp_rewards.length;

      updateBody.season_pass_road = {
        grind_reward_claimed: 0,
        sp_rewards: new Array(spFreeRewardsLength).fill(false),
        spp_rewards: new Array(spPremiumRewardsLength).fill(false),
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const restartSeasonWithDifferentQuests = () => {
      const updateBody = {};

      updateBody.season_pass_id = null;
      updateBody.season_pass_expire_date = null;
      updateBody.season_pass_start_date = null;
      updateBody.seasonal_quests_update_time = 0;

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const enableAllSevenDayRewards = () => {
      const updateBody = {
        login_rewards: userConstants.FULL_SEVEN_DAY_REWARDS,
      };
      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const enableAllDailyRewards = () => {
      const updateBody = {
        daily_login_rewards: JSON.parse(
          JSON.stringify(user.value.daily_login_rewards)
        ),
      };

      updateBody.daily_login_rewards.forEach((reward) => {
        reward.is_claimable = true;
        reward.claimed = false;
      });

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const refreshDailyQuests = () => {
      const updateBody = {
        daily_quest_expire_date: Date.now(),
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const refreshDailyShop = () => {
      const updateBody = {
        daily_shop_expire_date: Date.now(),
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const refreshEventRoadRewards = () => {
      if (user.value.event_data["treasure-hunt"]) {
        for (
          let i = 0;
          i < userEventData["treasure-hunt"].road_rewards_claimed.length;
          i++
        ) {
          userEventData["treasure-hunt"].road_rewards_claimed[i] = false;
        }
      }

      const updateBody = {
        event_data: userEventData,
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const refreshEventTreasureStates = () => {
      if (user.value.event_data["treasure-hunt"]) {
        for (
          let i = 0;
          i < userEventData["treasure-hunt"].treasure_states.length;
          i++
        ) {
          userEventData["treasure-hunt"].treasure_states[i].claimed = false;
        }
      }

      const updateBody = {
        event_data: userEventData,
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const changeEventPremiumStatus = () => {
      if (user.value.event_data["treasure-hunt"]) {
        userEventData["treasure-hunt"].is_premium =
          !userEventData["treasure-hunt"].is_premium;
      }

      const updateBody = {
        event_data: userEventData,
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const enableDailyDealsRefresh = () => {
      const updateBody = {
        daily_shop_last_refresh_date: 0,
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const maxCharApply = () => {
      const result = maxChar(JSON.parse(JSON.stringify(user.value)));
      store.dispatch("user/updateUser", { id: user.value.id, body: result });
      searchUser();
    };

    const setAllDailyQuestsCompleted = () => {
      const updateBody = {
        daily_quests: JSON.parse(JSON.stringify(user.value.daily_quests)),
      };

      updateBody.daily_quests.forEach((quest) => {
        quest.amount = quest.required_amount;
      });

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const setAllBeginnerQuestsCompleted = () => {
      const updateBody = {
        beginner_quests: JSON.parse(JSON.stringify(user.value.beginner_quests)),
      };

      updateBody.beginner_quests.forEach((quest) => {
        quest.current_amount = quest.amount;
      });

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const removeAllBeginnerQuests = () => {
      const updateBody = { removeBQ: true };
      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const resetEventTutorial = () => {
      const updateBody = { removeET: true };
      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const setAllSeasonalQuestsCompleted = (isPremium) => {
      const updateBody = {
        seasonal_quests: JSON.parse(JSON.stringify(user.value.seasonal_quests)),
      };

      const key = isPremium ? "premium" : "free";

      updateBody.seasonal_quests[key].forEach((quest) => {
        quest.amount = quest.required_amount;
      });

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const getPurchaseData = async () => {
      const data = await axios
        .post("api/purchases/get/all", {
          is_descending: false,
          filters: [{ type: "string", field: "user_id", value: user.value.id }],
          page: 0,
        })
        .then((res) => {
          return res.data;
        });
      totalPurchaseCount.value = data.length;
    };

    const obtainAllEmotes = () => {
      const updateBody = {
        emojis: [...Array(33).keys()],
      };

      store.dispatch("user/updateUser", {
        id: user.value.id,
        body: updateBody,
      });
    };

    const isUpdating = ref(false);
    const editUserFields = () => {
      let updateBody = {};
      if (editableTrophy.value !== user.value.trophy) {
        updateBody.trophy = editableTrophy.value;
        if (editableTrophy.value > user.value.highest_trophy) {
          updateBody.highest_trophy = editableTrophy.value;
          updateBody.is_trophy_manipulated = true;
        }
      }
      if (editableCoopKey.value !== user.value.coop_key) {
        updateBody.coop_key = editableCoopKey.value;
      }
      if (editableBan.value !== user.value.banned) {
        updateBody.banned = editableBan.value;
      }
      if (editableSPToken.value !== user.value.season_pass_token) {
        updateBody.season_pass_token = editableSPToken.value;
      }
      if (editableGem.value !== user.value.gem) {
        updateBody.gem = editableGem.value;
      }
      if (editableGold.value !== user.value.gold) {
        updateBody.gold = editableGold.value;
      }
      if (editableCreationDate.value !== user.value.create_date) {
        updateBody.create_date = editableCreationDate.value;
      }
      if (isLeaderboard.value !== user.value.exclude_leaderboard) {
        updateBody.exclude_leaderboard = isLeaderboard.value;
        if (isLeaderboard.value) {
          deleteFromLeaderboard();
        }
      }

      updateBody.coop = editableCoop.value;
      updateBody.testuser_matchmaking_type =
        user.value.testuser_matchmaking_type;
      updateBody.is_matchmaking_test_user = isMatchmakingTestUser.value;
      updateBody.is_premium = isPremiumUser.value;
      updateBody.next_login_reward_time = nextSevenDayRewardDate.value;
      updateBody.next_daily_login_reward_time = nextDailyRewardDate.value;
      updateBody.chests = adjustChests();
      updateBody.profile = {
        ...user.value.profile,
        victories: editableWin.value,
        defeats: editableLose.value,
        winrate:
          Math.floor(
            (editableWin.value / (editableWin.value + editableLose.value)) * 100
          ) || 0,
      };

      updateBody.event_data = JSON.parse(JSON.stringify(user.value.event_data));
      if (editableThematicEvent.value)
        updateBody.event_data["thematic_liveops"] = editableThematicEvent.value;
      if (editableTreasureHuntEvent.value)
        updateBody.event_data["treasure-hunt"] =
          editableTreasureHuntEvent.value;

      updateBody.units = JSON.parse(JSON.stringify(user.value.units));
      updateBody.heroes = JSON.parse(JSON.stringify(user.value.heroes));

      data.units.forEach((unit) => {
        updateBody.units[unit.id].count = unit.amount;
      });
      data.heroes.forEach((hero) => {
        updateBody.heroes[hero.id].count = hero.amount;
      });
      if (user.value.match_data) updateBody.match_data = user.value.match_data;
      isUpdating.value = true;
      store
        .dispatch("user/updateUser", { id: user.value.id, body: updateBody })
        .finally(() => {
          isUpdating.value = false;
          searchUser();
        });
    };
    const isDeleting = ref(false);

    const adjustChests = () => {
      const chestsResult = JSON.parse(JSON.stringify(user.value.chests));
      for (let i = 0; i < chestsResult.length; i++) {
        if (chestStates.value[i] != 1)
          chestsResult[i].opening_time = chestStates.value[i];
      }
      return chestsResult;
    };

    const deleteUser = () => {
      isDeleting.value = true;
      store.dispatch("user/deleteUser", { id: user.value.id }).finally(() => {
        isDeleting.value = false;
        popupOpen.value = false;
      });
    };

    const showPopup = () => {
      popupOpen.value = true;
    };

    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";

    return {
      selectedTabIndex,
      switchTab,
      tabOptions,
      pickedKey,
      pickedValue,
      searchUser,
      user,
      userConstants,
      prepareField,
      editableSPToken,
      editableBan,
      editableTrophy,
      editableCoopKey,
      editUserFields,
      resetUserTrophyRoad,
      claimTrophyRoad,
      completeZeroToHeroQuests,
      completeCareerQuest,
      resetUserSeasonPass,
      completeTutorial,
      resetTutorialProgress,
      deleteFromLeaderboard,
      restartSeasonWithDifferentQuests,
      editableGold,
      editableGem,
      data,
      unitsArr,
      heroesArr,
      unitPanelOpen,
      heroPanelOpen,
      toggleUnitPanel,
      toggleHeroPanel,
      editableWin,
      editableLose,
      editableCreationDate,
      chestsPanelOpen,
      toggleChestPanel,
      chests,
      nextSevenDayRewardDate,
      enableAllSevenDayRewards,
      enableAllDailyRewards,
      refreshDailyQuests,
      setAllDailyQuestsCompleted,
      setAllBeginnerQuestsCompleted,
      removeAllBeginnerQuests,
      setAllSeasonalQuestsCompleted,
      refreshDailyShop,
      obtainAllEmotes,
      enableDailyDealsRefresh,
      nextDailyRewardDate,
      totalPurchaseAmount,
      totalPurchaseCount,
      userSegment,
      isSearching,
      isUpdating,
      deleteUser,
      isDeleting,
      popupOpen,
      showPopup,
      popupText,
      popupType,
      isPremiumUser,
      isLeaderboard,
      env,
      maxCharApply,
      chestStates,
      isMatchmakingTestUser,
      refreshEventRoadRewards,
      refreshEventTreasureStates,
      changeEventPremiumStatus,
      resetEventTutorial,
      editableThematicEvent,
      editableTreasureHuntEvent,
      editableCoop,
    };
  },
};
</script>

<style scoped>
.tab-container {
  margin: 15px 30px;
}

.menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 15px 30px;
  border-radius: 10px;
  border: 1px gray solid;
}

.menu-item {
  cursor: pointer;
  font-size: 15px;
  padding: 10px 20px;
  min-width: 200px;
}

.menu-item-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.menu-item-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.menu-item:hover {
  background-color: lavender;
}

.menu-item-selected {
  background-color: khaki;
}

.search-bar,
.user-editable-content {
  display: flex;
  margin: 15px 30px;
  padding: 15px 30px;
  border-radius: 5px;
  border: 1px gray solid;
  justify-content: space-between;
  overflow-x: scroll;
}

.event-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 15px 30px;
  padding: 15px 30px;
  border-radius: 5px;
  border: 1px gray solid;
  justify-content: space-between;
}

.table-header {
  border: 1px gray solid;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}

.table-header:hover {
  background: gray;
}

.user-units-table {
  margin: 15px 30px;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
}

.user-units-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex-direction: row;
  justify-content: left;
  padding: 15px;
}

.user-chest-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-direction: row;
}

.user-chest {
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-height: 50px;
  border-radius: 15px;
  border: 1px solid gray;
}

input {
  margin-left: 5px;
}
input[type="text"] {
  width: 33%;
}
label {
  margin-right: 10px;
}

.user-content {
  padding: 10px 30px;
}
.container {
  width: 85vw;
}
.row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid black;
  padding: 10px 20px;
}

.row-noborder {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 10px 20px;
}

.form-container-event {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 5px;
}
</style>